@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

html, body {
  font-optical-sizing: auto;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

*{
  font-family: "Abel", sans-serif !important;
  overflow-x: hidden;
}


h1,h2,h3 {
  font-weight: 200;
}