
.footer-container{
  background: linear-gradient(45deg, #000000, #111111);
  background-size: 1000% 1000%; 
  background-position: 0% 50%; 
  animation: gradientBG 30s ease infinite;
  width: 100vw;
  padding: 2%;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer-icon a:hover {
  color: #871212;
  transition: color .5s ease-in-out;
}

.footer-icon a {
  color: white;
  font-size: 25px;
  padding: 1px 20px;

}

.privacy{
  display: flex;
  gap: 10px;
  justify-self: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  margin: 1%;
  cursor: pointer;
}

.privacy p:hover{
  text-decoration: underline;
  transition:  text-decoration .5s ease-in-out;
}

.footer-copyright p{
  text-align: center;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

@media (max-width: 600px) {
  .footer-container{
    padding: 7% 3%;
  }
  .footer-icon a { 
    font-size: 24px;
  }

  .footer-menu{
    flex-direction: column;
    text-align: center;
    padding: 2% 0;
  }

  .footer-menu a{
    padding: 1% 3%;
  }

  .footer-copyright p{
    font-size: smaller;
    padding-top: 5%;
  }
}