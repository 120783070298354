.header-container {
    z-index: 50;
}

.header-container.scrolled {
    z-index: 50;
}

.custom-menu {
    z-index: 20;
    position: fixed;
    font-size: 20px;
    display: flex;
    padding: 20px 50px 0;
    align-items: center;
    background-color: transparent;
    box-shadow: none;
    width: 100vw;
    border: none;
    top: 0;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, padding 0.3s ease;
}

.menu-right-web {
    padding-top: 0;
}

.menu-right-web a {
    color: #fff;
    top: 0;
}

.custom-menu .menu-right-web a {
    color: #fff;
    text-transform: uppercase;
}

.custom-menu .menu-right-web a:hover {
    color: #fff;
    text-decoration: #fff underline;
}

.ant-menu-overflow {
    padding: 0 auto;
}

.menu-left {
    flex: 1;
    display: flex;
    text-align: center;
}

.menu-logo {
    display: flex;
    align-items: center;
}

.menu-center-group {
    display: flex;
    flex: 2;
    justify-content: center;
}

.menu-left-web img {
    padding: 2% 0%;
    width: 100px;
    transition: all 0.3s ease;
}

.menu-right {
    flex: 1;
    display: flex;
    padding: 0;
    
}

.custom-menu .ant-menu-item {
    background: transparent;
}

.menu-right-web {
    align-self: flex-start;
}

.ant-layout-header {
    padding: 0;
    list-style: 0;
    height: 0;
}

.header-container button {
    background: none;
    border: 0;
}

.custom-menu-phone {
    display: flex;
    padding: 0 10px;
    align-items: stretch;
    width: 100vw;
    top: 0;
    z-index: 20;
}

.menu-right-phone {
    flex: 1;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 20px;
    padding-top: 12px;
}

.menu-right-phone a {
    color: #fff;
    font-size: small;
}

.menu-right-phone a:hover {
    color: #871212a1;
}

.ant-drawer .ant-drawer-content {
    background: #000;
    color: #fff;
    width: 100vw;
}

.custom-drawer .ant-drawer-content {
    background-color: transparent;
}

.custom-drawer .ant-drawer-title {
    color: #fff;
}

.custom-drawer .ant-menu-item {
    background: transparent;
    color: #fff;
}

.ant-drawer .ant-drawer-close {
    color: #fff;
}

.custom-drawer ul {
    background: transparent;
    color: #fff;
}

.custom-drawer .ant-menu-item:hover {
    background-color: #444;
}

.logo-mobile {
    padding-top: 10px;
}

.active-menu-item a {
    color: white !important;
    text-decoration: underline;
    font-weight: bold;
}

.logo {
    width: 10vw;
    height: 10vh;
    transition: all 0.2s ease-in-out;
}

.logo.small {
    width: 8vh;
    height: 8vh;
    opacity: .8;
}


.custom-menu.scrolled {
    padding: 20px 18px 0;
}

/* phone */
@media (max-width: 600px) {
    .ant-layout-header {
        height: max-content;
        background-color: transparent;
        position: fixed;
    }

    .menu-right-phone {
        flex: 10;
        padding-top: 0;
    }

    .section-we {
        font-size: smaller;
    }

    .section-we h3 {
        font-size: 11px;
    }


}

/* tablet */
@media (max-width: 768px) {}

/* laptops */
@media (max-width: 992px) {
    .custom-menu {
        font-size: 12px;
        padding: 0 5px;
        align-items: center;
    }
}

/* large */
@media (max-width: 1200px) {}